<template>
  <div class="text-center">
    <div
      class="logo-container"
      :style="{ 'background-image': 'url(' + src + ')', 'width' : '150px', 'height': '80px' }"
      :alt="title"
      :title="title"
    />
  </div>
</template>

<script>
export default {
  name: 'ClientLogo',
  props: {
    src: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
  .logo-container {
    display: inline-block;
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
  }
</style>
