<template>
  <div class="empty-list">
    <div>
      <img
        class="empty-list__img"
        src="../assets/img/noData.svg"
        alt="Empty picture"
      >
    </div>
    <div>
      <span>{{ message }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'EmptyList',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";
.empty-list {
    text-align: center;
    font-size: 16px;
    padding: 6em 0;
    &__img {
      margin: 3em 0
    }
}
</style>
