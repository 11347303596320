<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style lang="scss">
html {
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
}

ul {
  margin: 0;
}
.app-content {
  padding: 60px 15px 0;
}

@media only screen and (max-width: 480px) {
  .well {
    border:0;
  }
}
</style>
