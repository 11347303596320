<template>
  <div
    v-loading="loading"
    class="processing"
    :element-loading-text="message"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <span
      v-if="template && !newRun"
      class="processing__date"
    >
      {{ $t('TEMPLATES.DATE_RUN') }} {{ $moment(template.results.created_at).format('MMMM Do YYYY, HH:mm:ss') }} {{ $t('TEMPLATES.BY') }} {{ template.results.user_email }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Processing',
  props: {
    message: {
      type: String,
      default: ''
    },
    newRun: {
      type: Boolean,
      default: false
    },
    template: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: true
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";
.processing {
    text-align: center;
    font-size: 16px;
    padding: 6em 0;
    &__date {
      color: #888;
      top: 80px;
      position: relative;
    }
    &__img {
      margin: 3em 0
    }
    .el-loading-spinner {
      i {
        font-size: 35px;
      }
      .el-loading-text {
        color: #888;
        font-size: 23px;
      }
    }
}
</style>
