<template>
  <div>
    <el-dialog
      custom-class="edit-referencial-modal"
      :visible="showEditReferencialModal"
      :append-to-body="true"
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      width="60%"
    >
      <!-- DIALOG TITLE -->
      <span
        v-if="selectedParam"
        slot="title"
      >
        {{ selectedParam.display_name }}
      </span>

      <!-- DIALOG BODY -->
      <div>
        <el-form
          ref="form"
          :model="form"
          status-icon
          :rules="rules"
          label-width="150px"
        >
          <div class="item-tablename">
            <el-form-item
              :label="$t('PARAMETERS.REFERENCIAL.REFERENCIAL')"
              prop="ref_tablename"
            >
              <el-row
                :gutter="24"
                type="flex"
                align="top"
              >
                <el-col :span="17">
                  <el-input
                    v-model="form.ref_tablename"
                    v-loading="loading"
                    width="100px"
                    :disabled="loading"
                    placeholder=""
                    @keyup.native="searchTable"
                  />
                </el-col>
                <el-col
                  class="line"
                  :span="7"
                >
                  <el-button
                    round
                    type="primary"
                    @click="searchTable(form.ref_tablename)"
                  >
                    {{ $t('BUTTONS.SEARCH') }}
                  </el-button>
                </el-col>
              </el-row>
              <el-alert
                v-if="errorTable"
                :closable="false"
                :title="$t('PARAMETERS.REFERENCIAL.ERROR_TABLE')"
                type="error"
              />
            </el-form-item>
          </div>
          <el-form-item
            :label="$t('PARAMETERS.REFERENCIAL.ID_COLUMN')"
            prop="ref_idcolumn"
          >
            <el-select
              v-model="form.ref_idcolumn"
              :disabled="errorTable || loading || isTyping"
              placeholder=""
            >
              <el-option
                v-for="item in tableColumns"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('PARAMETERS.REFERENCIAL.VALUE_COLUMN')"
            prop="ref_valuecolumn"
          >
            <el-select
              v-model="form.ref_valuecolumn"
              :disabled="errorTable || loading || isTyping"
              placeholder=""
            >
              <el-option
                v-for="item in tableColumns"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            :label="$t('PARAMETERS.REFERENCIAL.ORDER_BY')"
            prop="ref_ordercolumn"
          >
            <el-select
              v-model="form.ref_ordercolumn"
              :disabled="errorTable || loading || isTyping"
              placeholder=""
            >
              <el-option
                v-for="item in tableColumns"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label=""
            prop="ref_orderdesc"
          >
            <el-checkbox
              v-model="form.ref_orderdesc"
              :disabled="errorTable || loading || isTyping"
            >
              DESC
            </el-checkbox>
          </el-form-item>
          <el-form-item
            :label="$t('PARAMETERS.REFERENCIAL.GROUP_COLUMN') + ' (' + $t('OPTIONAL') + ')'"
            prop="ref_groupcolumn"
          >
            <el-select
              v-model="form.ref_groupcolumn"
              clearable
              :disabled="errorTable || loading || isTyping"
              placeholder=""
            >
              <el-option
                v-for="item in tableColumns"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              round
              type="primary"
              :loading="loading"
              :disabled="errorTable"
              @click="submitForm('form')"
            >
              {{ $t('BUTTONS.SAVE') }}
            </el-button>
            <el-button
              round
              @click="cancelAction()"
            >
              {{ $t('BUTTONS.CANCEL') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'EditReferencialModal',
  props: {
    showEditReferencialModal: {
      type: Boolean,
      default: false
    },
    selectedParam: {
      type: Object,
      default: () => {}
    },
    clientId: {
      type: Number,
      required: true
    }
  },
  data () {
    var validateForm = (rule, value, callback) => {
      if (!value || value === '') {
        callback(new Error(this.$t('PARAMETERS.REFERENCIAL.ERROR_VALUE')))
      } else {
        callback()
      }
    }
    return {
      errorTable: false,
      tableColumns: null,
      loading: false,
      typingTimer: null,
      isTyping: false,
      form: null,

      rules: {
        ref_tablename: [
          { validator: validateForm, trigger: 'blur' }
        ],
        ref_idcolumn: [
          { validator: validateForm, trigger: 'blur' }
        ],
        ref_valuecolumn: [
          { validator: validateForm, trigger: 'blur' }
        ],
        ref_ordercolumn: [
          { validator: validateForm, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.form = this.selectedParam
    if (this.form.ref_tablename) {
      this.getTableColumns(true)
    } else {
      this.errorTable = true
    }
  },
  methods: {
    cancelAction (saved) {
      this.$emit('update:selectedParam', null)
      this.$emit('update:showEditReferencialModal', false)
      this.$refs['form'].resetFields()
      if (saved === true) {
        this.$emit('update:newReferencial', true)
      }
    },
    submitAction () {
      this.$emit('update:showEditReferencialModal', false)
    },
    searchTable () {
      this.isTyping = true
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => {
        this.getTableColumns()
      }, 1000)
    },
    submitForm (formName) {
      if (this.errorTable) {
        return false
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          let parameters = []
          parameters.push(this.form)
          this.$store.dispatch('parameters/updateParameters', { clientId: this.clientId, parameters: parameters })
            .then(() => {
              this.loading = false
              this.cancelAction(true)
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          this.loading = false
          return false
        }
      })
    },
    getTableColumns (init = false) {
      this.errorTable = false
      if (this.form.ref_tablename) {
        this.loading = true
        this.$store.dispatch('parameters/getTableColumns', { clientId: this.clientId, tableName: this.form.ref_tablename })
          .then(response => {
            if (response && response.data.columns.length === 0) {
              this.errorTable = true
              this.tableColumns = null
            } else {
              // Display current table ref
              this.tableColumns = response.data.columns
              // Only change the ref when searching new table ref
              if (!init) {
                this.form.ref_idcolumn = null
                this.form.ref_ordercolumn = null
                this.form.ref_valuecolumn = null
                if (response.data.id_column) {
                  this.form.ref_idcolumn = response.data.id_column
                  this.form.ref_ordercolumn = response.data.id_column
                }
                if (response.data.value_column) {
                  this.form.ref_valuecolumn = response.data.value_column
                }
              }
              this.errorTable = false
              this.isTyping = false
            }
            this.loading = false
            this.isTyping = false
          })
          .catch(() => {
            this.loading = false
            this.errorTable = true
            this.isTyping = false
          })
      } else {
        this.errorTable = true
      }
    }
  }
}
</script>

<style lang="scss">
.edit-referencial-modal {
  .el-form-item__label,
  .el-checkbox__label {
    color: #888;
  }
  .el-select {
    width: 100%
  }
  .el-dialog {
    &__header {
      border-bottom: 1px solid #eee;
      padding: 1rem;
    }

    &__body {
      color: #888;

      .el-row {
        .el-col {
          margin: 10px 0;
        }
      }
    }
  }

  .item-tablename {
    label {
      padding-top: 10px;
    }
  }
}
@media only screen and (max-width: 990px) {
  .edit-referencial-modal {
    width: 90% !important;
  }
}
</style>
