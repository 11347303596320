import Vue from 'vue'
import config from '@/config'

const SET_ITEMS = 'SET_ITEMS'
const SET_TOTAL = 'SET_TOTAL'
const SET_LOADING = 'SET_LOADING'
const SET_LOADING_CALCULATE = 'SET_LOADING_CALCULATE'
const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE'

const moduleTemplates = {
  namespaced: true,
  state: {
    item: null,
    items: [],
    loading: false,
    loadingCalculate: false,
    currentTemplate: null
  },
  mutations: {
    [SET_ITEMS] (state, items) {
      Vue.set(state, 'items', items)
    },
    [SET_TOTAL] (state, total) {
      Vue.set(state, 'total', total)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    },
    [SET_LOADING_CALCULATE] (state, loadingCalculate) {
      Vue.set(state, 'loadingCalculate', loadingCalculate)
    },
    [SET_CURRENT_TEMPLATE] (state, currentTemplate) {
      Vue.set(state, 'currentTemplate', currentTemplate)
    }
  },
  actions: {
    getItem ({ commit }, { clientId, templateId }) {
      const url = `${config.API_URL}/clients/${clientId}/templates/${templateId}`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    getItems ({ commit }, { clientId }) {
      const url = `${config.API_URL}/clients/${clientId}/templates`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            let objects = response.data
            commit(SET_ITEMS, objects)
            resolve(response)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    getParameters ({ commit }, { clientId, templateId }) {
      const url = `${config.API_URL}/clients/${clientId}/templates/${templateId}/parameters`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            let objects = response.data.objects
            commit(SET_CURRENT_TEMPLATE, {
              'clientId': clientId.toString(),
              'templateId': templateId.toString()
            })
            commit(SET_ITEMS, objects)
            resolve(objects)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            commit(SET_CURRENT_TEMPLATE, null)
            reject(error)
          })
      })
    },
    calculate ({ commit }, form) {
      const url = `${config.API_URL}/clients/${form.clientId}/templates/${form.templateId}/calculate`
      commit(SET_LOADING_CALCULATE, true)
      return new Promise((resolve, reject) => {
        Vue.axios.post(url, {
          optin_type_id: form.optinType,
          parameters: form.parameters
        })
          .then(response => {
            if (response.status !== 200) {
              reject(new Error(response.response.data.message))
            }
            commit(SET_LOADING_CALCULATE, false)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING_CALCULATE, false)
            reject(error)
          })
      })
    },
    cancel (_ , {clientId, templateId, tokenId }) {
      const url = `${config.API_URL}/clients/${clientId}/templates/${templateId}/cancel`
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, {
          token_id: tokenId
        })
          .then(response => {
            if (response.status !== 200) {
              reject(new Error(response.response.data.message))
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    updateTemplate ({ commit }, form) {
      const url = `${config.API_URL}/clients/${form.clientId}/templates/${form.templateId}/update`
      commit(SET_LOADING_CALCULATE, true)
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, {
          display_name: form.display_name,
          parameters: form.parameters
        })
          .then(response => {
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            commit(SET_LOADING_CALCULATE, false)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING_CALCULATE, false)
            reject(error)
          })
      })
    }
  },
  getters: {
    item: state => state.item,
    items: state => state.items,
    total: state => state.total,
    loading: state => state.loading,
    loadingCalculate: state => state.loadingCalculate,
    currentTemplate: state => state.currentTemplate
  }
}

export default moduleTemplates
