<template>
  <div class="edit-columns-wrapper">
    <div class="edit-columns">
      <div class="edit-columns__draggable-wrap">
        <draggable
          v-bind="dragOptions"
          handle=".btn.drag"
          :list="orderedList"
          :move="changeOrder"
          @start="dragging = true"
          @end="dragging = false"
        >
          <div
            v-for="column in orderedList"
            :key="column.id"
            class="edit-columns__item"
          >
            <!-- # NAME OF COLUMN # -->
            <div
              :ref="`column-${column.id}`"
              class="edit-columns__item-name name"
            >
              {{ column.display_name }}
            </div>

            <!-- # DRAGGABLE BUTTON # -->
            <el-button
              type="text"
              class="btn drag"
            >
              <i class="material-icons">drag_handle</i>
            </el-button>
          </div>
        </draggable>
      </div>
    </div>
    <div class="edit-columns-footer text-center">
      <el-button
        type="primary"
        size="medium"
        :loading="loading"
        round
        @click="submit"
      >
        {{ $t('BUTTONS.SAVE') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'
import draggable from 'vuedraggable'

export default {
  name: 'EditParametersOrderPanel',
  components: { draggable },
  props: {
    list: { type: Array, required: true },
    visible: { type: Boolean, required: true }
  },
  data () {
    return {
      loading: false,
      dragging: false,
      orderedList: []
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 200,
        disabled: false
      }
    }
  },
  created () {
    this.orderFields()
  },
  methods: {
    orderFields () {
      this.orderedList = orderBy(this.list, ['display_order'], ['asc'])
    },
    changeOrder (item) {
      let currentItemIndex = item.draggedContext.index
      let futureIndex = item.draggedContext.futureIndex
      let currentItem = this.orderedList[currentItemIndex]
      currentItem.display_order = futureIndex
    },
    submit () {
      this.loading = true
      // Loop every orderedList item and add the right display order
      for (let i = 0; i < this.orderedList.length; i++) {
        const field = this.orderedList[i]
        field.display_order = i + 1
      }
      this.$emit('update', this.orderedList)
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-columns-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
}
.edit-columns {
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  bottom: 65px;
  overflow-y: auto;

  &__footer {
    background: white;
    padding: 2rem;
    position: fixed;
    bottom: 0;
    width: 430px;
    z-index: 10;
  }
  &__draggable-wrap{
    padding: 20px 2px 20px 20px;
  }
  &__item {
    display: flex;
    margin-bottom: 15px;
    &-name {
      width: 90%;
      border: 1px solid #dee2e6;
      border-radius: 3px;
      font-size: 12px;
      &.name {
        padding: 12px 10px;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .btn.drag {
      transform: rotate(90deg);
      color: #dee2e6;
      position: relative;
      padding: 0 5px;
      width: 10%;
    }
  }
  &__item-header {
    cursor: pointer;
  }
}

</style>
