<template>
  <div
    v-loading="loading"
    class="proc-results"
  >
    <el-collapse
      v-model="activeTab"
      class="proc-results__content"
    >
      <!-- USER DETAILS -->
      <el-collapse-item
        :title="$t('RESULTS.DETAILS')"
        name="details"
      >
        <el-row
          v-if="user"
          :gutter="24"
        >
          <el-col :span="5">
            <b>{{ $t('RESULTS.TESTER') }}</b>
          </el-col>
          <el-col :span="19">
            {{ user }}
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="5">
            <b>{{ $t('RESULTS.TEMPLATE_TITLE') }}</b>
          </el-col>
          <el-col
            v-if="template"
            :span="19"
          >
            {{ template.display_name }}
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="5">
            <b>{{ $t('RESULTS.TEMPLATE_TOKEN_ID') }}</b>
          </el-col>
          <el-col
            v-if="results"
            :span="19"
          >
            {{ results.token_id }}
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="5">
            <b>SP_name</b>
          </el-col>
          <el-col
            v-if="template"
            :span="19"
          >
            {{ template.name }}
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="5">
            <b>{{ $t('RESULTS.DATE') }}</b>
          </el-col>
          <el-col :span="19">
            {{ this.$moment(results.created_at).format('MMMM Do YYYY, HH:mm:ss') }}
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="5">
            <b>{{ $t('RESULTS.DURATION') }}</b>
          </el-col>
          <el-col :span="19">
            {{ duration }}
          </el-col>
        </el-row>
      </el-collapse-item>

      <!-- USED PARAMETERS -->

      <el-collapse-item
        :title="$t('RESULTS.PARAMETERS')"
        name="parameters"
      >
        <el-table
          :data="usedParameters"
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            min-width="300"
            :label="$t('RESULTS.PARAMETER')"
          />
          <el-table-column
            :label="$t('RESULTS.VALUE')"
            min-width="500"
          >
            <template slot-scope="scope">
              <div
                v-for="param in scope.row.params"
                :key="param.value_id"
              >
                <el-tag
                  v-if="param.value_id != param.value"
                  type="info"
                  size="mini"
                >
                  {{ param.value_id }}
                </el-tag>
                <span v-if="param.type === 6"> {{ $moment(param.value).format('MMMM Do YYYY') }}</span>
                <span v-else> {{ param.value }}</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-collapse-item>

      <div v-if="!results.error_msg">
        <!-- LOSS DETAILS -->
        <el-collapse-item
          :title="$t('RESULTS.LOSS')"
          name="loss"
        >
          <div v-if="!results.error_msg">
            <!-- If sortie cible = 1, do not show loss results -->
            <el-alert
              v-if="sortieCible === '1'"
              :title="$t('RESULTS.TARGETS_GENERATED')"
              type="success"
              :closable="false"
              show-icon
            />
            <!-- If sortie cible = 0, show loss results -->
            <el-table
              v-if="(!sortieCible || sortieCible === '0')"
              :data="results.deperdition"
              style="width: 100%"
              :empty-text="$t('RESULTS.NO_RESULTS')"
            >
              <el-table-column
                prop="description"
                min-width="300"
                :label="$t('RESULTS.DESC')"
              />
              <el-table-column
                prop="volume"
                :label="$t('RESULTS.VOLUME')"
                width="180"
              />
              <el-table-column
                prop="loss"
                :label="$t('RESULTS.LOSS')"
                width="180"
              />
            </el-table>
          </div>

          <div class="error">
            {{ results.error_msg }}
          </div>
        </el-collapse-item>

        <!-- ACTIVE VOLUME/INACTIVE VOLUME -->
        <el-collapse-item
          v-if="(!sortieCible || sortieCible === '0')"
          :title="$t('RESULTS.SPLITNDD')"
          name="splitndd"
        >
          <!-- If sortie cible = 0, show split ndd results -->
          <el-table
            :data="results.population_volumes"
            style="width: 100%"
            :empty-text="$t('RESULTS.NO_RESULTS')"
          >
            <el-table-column
              prop="PopulationName"
              min-width="500"
              :label="$t('RESULTS.POPULATION')"
            />
            <el-table-column
              prop="Volume"
              :label="$t('RESULTS.VOLUME')"
              width="500"
            />
          </el-table>
        </el-collapse-item>

        <!-- Target COLUMNS -->
        <el-collapse-item
          v-if="(!sortieCible || sortieCible === '0')"
          :title="$t('RESULTS.TARGET_COLUMNS')"
          name="target-columns"
        >
          <div v-if="results.target_columns">
            <ul
              v-for="(field, index) in results.target_columns"
              :key="index"
            >
              {{ field }}
            </ul>
            <!-- {{results.target_columns}} -->
          </div>
          <div v-else>
            {{ $t('RESULTS.MISSING_TARGET_COLUMNS') }}
          </div>
        </el-collapse-item>
        <!-- VIP PROC DETAILS -->
        <el-collapse-item
          v-if="sortieCible === '1'"
          :title="$t('RESULTS.VIP_PROC')"
          name="vip"
        >
          <div>
            <el-alert
              v-if="!results.vip_finished_at"
              :title="$t('RESULTS.VIP_PROC_RUNNING')"
              :closable="false"
              type="warning"
              show-icon
            />
            <el-alert
              v-else-if="results.vip_finished_at && !results.vip_error_msg"
              :title="$t('RESULTS.VIP_PROC_SUCCESS')"
              :closable="false"
              type="success"
              show-icon
            />
            <el-alert
              v-else
              :title="$t('RESULTS.VIP_PROC_ERROR') + results.vip_error_msg"
              :closable="false"
              type="error"
              show-icon
            />
          </div>
        </el-collapse-item>
      </div>

      <div v-else>
        <!-- ERROR -->
        <el-collapse-item
          :title="$t('RESULTS.ERROR_SQL')"
          name="error"
        >
          <div class="error">
            {{ results.error_msg }}
          </div>
        </el-collapse-item>
      </div>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: 'ProcResults',
  props: {
    template: {
      type: Object,
      required: true
    },
    results: {
      type: Object,
      required: true
    },
    usedParameters: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    sortieCible: {
      type: String,
      default: ''
    },
    user: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      activeTab: ['details', 'parameters', 'loss', 'splitndd', 'target-columns', 'error', 'vip'],
      duration: ''
    }
  },
  created () {
    if (this.results.parameters) {
      this.initResults()
    }
  },
  methods: {
    initResults () {
      // Check sortie cible
      for (let i = 0; i < this.results.parameters.length; i++) {
        let param = this.results.parameters[i]
        if (param.parameter === 'sortiecible') {
          this.$emit('update:sortieCible', param.value)
        }
      }
      if (this.results.created_at && this.results.finished_at) {
        let created = this.$moment(this.results.created_at)
        let finished = this.$moment(this.results.finished_at)
        let duration = this.$moment.duration(finished.diff(created))
        this.duration = this.$moment(duration.as('milliseconds')).format('mm:ss')
      }
    }
  }
}
</script>

<style lang="scss">
.proc-results {
  &__content {
    margin: 2rem 0;

    .error {
      font-size: 16px;
      color: #c70000;
    }
    .el-collapse-item__header {
      padding: 5px 20px;
      font-size: 16px;

      &.is-active {
        font-weight: bold;
      }
    }

    .el-collapse-item__content {
      padding: 20px
    }

    .el-table {
      td {
        color: #888888
      }
      &__row:last-child {
        td {
          border-bottom: none;
        }
      }
    }

  }
}
</style>
