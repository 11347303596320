import Vue from 'vue'
import Vuex from 'vuex'

import moduleAuth from './auth'
import moduleClients from './clients'
import moduleUsers from './users'
import moduleTemplates from './templates'
import moduleParameters from './parameters'
import moduleResults from './results'
import moduleSwitch from './switch'

Vue.use(Vuex)

const storeModule = {
  auth: moduleAuth,
  clients: moduleClients,
  users: moduleUsers,
  templates: moduleTemplates,
  parameters: moduleParameters,
  results: moduleResults,
  switch: moduleSwitch
}

// Store
const store = new Vuex.Store({
  modules: storeModule
})

export default store
