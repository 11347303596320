<template>
  <img
    :src="logoUrl"
    :style="styles"
    alt="TargetBO logo"
  >
</template>

<script>
import whiteLogo from '@/assets/img/logo-white.svg'

export default {
  name: 'Logo',
  computed: {
    logoUrl () {
      return whiteLogo
    },
    styles () {
      let css = 'margin: auto;'
      css += 'max-height: 36px;'
      return css
    }
  }
}
</script>
