const config = {
  API_URL: '/api',
  LOGIN_PAGE_URL: '/login?url={redir_url}',
  LOGOUT_PAGE_URL: '/logout?url={redir_url}',
  CHANNELS: {
    EMAIL: 1,
    SMS: 2,
    PUSH_NOTIFICATION: 3
  },

  PARAM_TYPE_ID: {
    TEXT_INPUT: 1,
    NUMBER_INPUT: 2,
    YES_NO_RADIO_BTN: 3,
    DROPDOWN_LIST: 4,
    MULTI_SELECT: 5,
    DATEPICKER: 6,
    TEXT_AREA: 7
  }
}

export default config
