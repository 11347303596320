<template>
  <div
    class="search-input"
    :class="{'full-width': fullWidth, 'm-auto': marginAuto}"
    :style="{ width: width + 'px' }"
  >
    <input
      v-model="query"
      type="text"
      class="search-input__input"
      name="client-search"
      :placeholder="placeholder"
      @input="callback(query)"
    >
    <span class="search-input__suffix">
      <i class="material-icons search-input__icon">search</i>
    </span>
  </div>
</template>

<script>

export default {
  name: 'SearchBar',
  props: {
    fullWidth: {
      type: Boolean,
      default: false
    },
    marginAuto: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: 800
    },
    placeholder: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      query: ''
    }
  }
}
</script>

<style lang="scss">
.search-input {
  position: relative;
  font-size: 14px;
  width: 800px;
  overflow: hidden;

  &.m-auto {
    margin: auto;
  }

  .search-input__input {
    -webkit-appearance: none;
    border: 0;
    border-bottom: 1px solid #afafaf;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    outline: none;
    padding: 0;
    padding-left: 40px;
    width: 100%;
    background-color: transparent;
  }

  .search-input__suffix {
    position: absolute;
    height: 100%;
    left: 5px;
    top: 0;
    text-align: center;

    .search-input__icon {
        width: 25px;
        line-height: 40px;
    }
  }
}

</style>
