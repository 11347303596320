<template>
  <div>
    <AppHeader />
    <div class="page__container">
      <div class="body-content">
        <transition>
          <router-view />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { AppHeader } from '@/components'

export default {
  name: 'MainContainer',
  components: { AppHeader },
}
</script>
<style lang="scss">
@import "src/assets/style/_variables.scss";

.body-content {
  width: 100%;
  padding: 0px 50px 80px 50px;
  box-sizing: border-box;
  position: relative;
  margin: auto;
}

.page__container {
    width: 100%;
    position: relative;
    margin: $--header-height auto auto;
    overflow-y: auto;
    height: calc(100vh - $--header-height);
  }

h1 {
  font-size: 1.3rem;
  color: $--color-primary;
  font-weight: normal;
}

// Responsive
@media only screen and (max-width: 800px) {
  .content {
    max-width: 100%;
    margin: 0;
    padding: 20px;
  }
}
</style>
