import Vue from "vue"


const SET_APPS = 'SET_APPS'
const SET_CLIENTS = 'SET_CLIENTS'

const moduleSwitch = {
  namespaced: true,
  state: {
    apps: [],
    clients: [],
  },
  mutations: {
    [SET_APPS] (state, apps) {
      Vue.set(state, 'apps', apps)
    },
    [SET_CLIENTS] (state, clients) {
      Vue.set(state, 'clients', clients)
    },
  },
  actions: {
    async getApps ({ commit }, client_id) {
      return new Promise((resolve, reject) => {
        if(!client_id) {
          commit(SET_APPS, [])
          resolve()
        } else {
          const url = `/gate/clients/${client_id}/apps`
          Vue.axios.get(url)
          .then(response => {
            if (response.status === 200) {
              commit(SET_APPS, response.data['apps'])
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
        }
        
      })
    },
    async getClients ({ commit }) {
      const url = '/gate/clients'
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            if (response.status === 200) {
              commit(SET_CLIENTS, response.data)
              resolve(response)
            } else {
              reject(response)
            }
          })
          .catch(error => {
            reject(error.response ? error.response : error)
          })
      })
    },
  },
  getters: {
    apps: (state) => state.apps,
    clients: (state) => state.clients
  }
}

export default moduleSwitch
