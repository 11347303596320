// Load vue relative library
import Vue from 'vue'
import VueAxios from 'vue-axios'

// Load extern library
import axios from 'axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import VueHolder from 'vue-holderjs'
import tzData from 'moment-timezone/builds/moment-timezone-with-data-1970-2030.min.js'
import vueMoment from 'vue-moment'
import infiniteScroll from 'vue-infinite-scroll'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

// Load intern component
import App from './App'
import router from './router'
import eventBus from './event-bus'

import store from './store'
import i18n from './languages/i18n'
import '../theme/index.css'
import './assets/style/app.scss'

window.moment = tzData

Vue.use(VueAxios, axios)
Vue.use(ElementUI, { locale })
Vue.use(vueMoment)
Vue.use(VueHolder)
Vue.use(infiniteScroll)

// Setup
Vue.config.productionTip = false

// SET LANGUAGE
i18n.locale = store.getters['language/value']
Vue.moment.locale(store.getters['language/value'])

// SET DEFAULT TZ TO EUROPE PARIS
// TODO SET TZ BASED ON USER TZ
// Vue.moment.tz.setDefault('Europe/Paris')

// Interceptor to redirect on standard error, and intercept and store
// the token returned by the back-end
axios.interceptors.response.use(function (response) {
  return response
}, function (error) {
  if (error.response.status !== 401 && error.response.status !== 303) {
    ElementUI.Notification({
      type: 'error',
      title: i18n.t('ERROR_DEFAULT_TITLE'),
      message: error.message
    })
  }
  if (error.response.status === 403) {
    // TODO create and redirect to a 403 page
  } else if (error.response.status === 500 && process.env.DEBUG_MODE) {
    /* eslint-disable no-console */
    console.error(`ERROR 500 - ${error}`)
    /* eslint-enable no-console */
  } else if (error.response.status === 404) {
    router.push('/')
  } else if (error.response.status === 401) {
    store.dispatch('auth/login')
  }
  return error
})

// scroll to top and init page's title when route changes
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  document.title = i18n.t(to.meta.title)
  next()
})

/* eslint-disable no-console */
console.info(`Version:%c ${process.env.VERSION}`, 'color: #00A5B5;')
/* eslint-enable no-console */

// set the eventBus globally
Vue.prototype.$eventBus = eventBus

if (process.env.SENTRY_ENV){
  const currentHost = window.location.hostname.split(".")

  if (currentHost.length == 3 && currentHost[0] == 'target-bo') {
    currentHost.splice(0, 1)
    Sentry.init({
      Vue,
      dsn: 'https://b8ba2be42a0145f39283b9d2fcb88abf@sentry.numberly.net/417',
      environment: process.env.SENTRY_ENV,
      release: process.env.VERSION,
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [currentHost.join('.')],
        }),
      ],
      tracesSampleRate: 1.0,
    })
  }
}


new Vue({
  router,
  store,
  i18n,
  eventBus,
  render: h => h(App)
}).$mount('#app')
