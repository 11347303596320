<template>
  <div class="clients-list">
    <ul class="cards-container">
      <!-- Clients -->
      <router-link
        v-for="item in items"
        :key="item.id"
        :to="{ name: 'clients-templates', params: { clientId: item.id } }"
        tag="li"
        class="card-item"
      >
        <clients-list-item
          :client="item"
        />
      </router-link>
    </ul>
  </div>
</template>

<script>
import ClientsListItem from '@/components/Clients/ClientsListItem'

export default {
  name: 'ClientsList',
  components: { ClientsListItem },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scope>

.clients-list {
  min-height: 300px
}

.cards-container {
  list-style-type: none;
  padding: 30px 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: auto;
  max-width: calc(3*(250px + 2*5px + 2*1px)); /* width + 2*margin + 2*border */
}

.card-item {
  width: 250px;
  height: 150px;
  margin: 5px;
  cursor: pointer;
}

// New item dialog form
.dialog-form {
  width: 60%;
  margin: auto;

  .el-input, .el-select, .el-input__inner {
    width: 100%;
  }

  .el-form-item:last-child {
    margin-bottom: 0;
  }
}

// Responsive
@media only screen and (max-width: 800px) {
  .cards-container {
    justify-content: center;
  }
}
</style>
