import Vue from 'vue'
import config from '@/config'

const SET_CURRENT_RESULTS = 'SET_CURRENT_RESULTS'

const moduleResults = {
  namespaced: true,
  state: {
    currentResults: null
  },
  mutations: {
    [SET_CURRENT_RESULTS] (state, currentResults) {
      Vue.set(state, 'currentResults', currentResults)
    }
  },
  actions: {
    setCurrentResults ({ commit }, currentResults) {
      commit(SET_CURRENT_RESULTS, currentResults)
    },
    getResults ({ commit }, { clientId, resultId }) {
      const url = `${config.API_URL}/clients/${clientId}/results/${resultId}`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            if (response.status !== 200) {
              reject(response.response)
            }
            commit(SET_CURRENT_RESULTS, response.data)
            resolve(response)
          })
          .catch(error => {
            commit(SET_CURRENT_RESULTS, null)
            reject(error)
          })
      })
    },
    getSamples (_, { clientId, templateId }) {
      const url = `${config.API_URL}/clients/${clientId}/templates/${templateId}/samples`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            if (response.status !== 200) {
              reject(response.response)
            }
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    checkStatus (_, { clientId, tokenId }) {
      const url = `${config.API_URL}/clients/${clientId}/results/status`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {
          params: {
            'token_id': tokenId
          }
        })
          .then(response => {
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
  },
  getters: {
    currentResults: state => state.currentResults
  }
}

export default moduleResults
