import Vue from 'vue'
import config from '@/config'

const SET_CLIENT = 'SET_CLIENT'
const SET_CLIENTS = 'SET_CLIENTS'
const SET_TOTAL_CLIENTS = 'SET_TOTAL_CLIENTS'
const SET_LOADING_CLIENTS = 'SET_LOADING_CLIENTS'
const SET_MULTI_CLIENT_MODE = 'SET_MULTI_CLIENT_MODE'

const moduleClients = {
  namespaced: true,
  state: {
    items: [],
    loading: false,
    multiMode: false
  },
  mutations: {
    [SET_CLIENT] (state, client) {
      Vue.set(state, 'item', client)
    },
    [SET_CLIENTS] (state, clients) {
      Vue.set(state, 'items', clients)
    },
    [SET_TOTAL_CLIENTS] (state, total) {
      Vue.set(state, 'total', total)
    },
    [SET_LOADING_CLIENTS] (state, loading) {
      Vue.set(state, 'loading', loading)
    },
    [SET_MULTI_CLIENT_MODE] (state, multiMode) {
      Vue.set(state, 'multiMode', multiMode)
    }

  },
  actions: {
    setClient ({ dispatch }, clientId) {
      dispatch('getItem', clientId)
    },
    getItem ({ commit }, clientId) {
      const url = `${config.API_URL}/clients/${clientId}`
      commit(SET_LOADING_CLIENTS, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING_CLIENTS, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            commit(SET_CLIENT, response.data)
            resolve(response.data)
          })
          .catch(error => {
            commit(SET_LOADING_CLIENTS, false)
            reject(error)
          })
      })
    },
    getItems ({ commit }, data) {
      let url = `${config.API_URL}/clients`
      commit(SET_LOADING_CLIENTS, true)

      if (data.name) {
        data.name = '%' + data.name
      }

      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: data })
          .then(response => {
            commit(SET_LOADING_CLIENTS, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            let clients = response.data.clients
            let multiMode = false
            if (clients.length > 1) {
              multiMode = true
            }
            commit(SET_MULTI_CLIENT_MODE, multiMode)
            commit(SET_CLIENTS, clients)
            commit(SET_TOTAL_CLIENTS, response.data.total)
            resolve(response)
          })
          .catch(error => {
            commit(SET_LOADING_CLIENTS, false)
            reject(error)
          })
      })
    }
  },
  getters: {
    item: state => state.item,
    items: state => state.items,
    total: state => state.total,
    loading: state => state.loading,
    multiMode: state => state.multiMode
  }
}

export default moduleClients
