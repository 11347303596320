<template>
  <div class="page-wrapper template-edit">
    <Navigation
      :router="{name: 'clients-templates', params: {clientId: clientId}}"
      :client="client"
      :title="$t('TEMPLATES.EDIT.TITLE')"
      :subtitle="template ? template.name : ''"
    />

    <el-row
      v-if="template"
      class="template-edit__name"
      type="flex"
      align="middle"
      justify="end"
    >
      <el-col>
        <div class="template-edit__name-label">
          {{ $t('TEMPLATES.EDIT.NAME') }} :
        </div>
        <el-input
          v-model="template.display_name"
          v-loading="saveLoading"
          class="template-edit__name-input"
          placeholder="Template name"
        />
      </el-col>
      <div class="template-edit__change-order">
        <el-button
          plain
          round
          type="primary"
          :loading="saveLoading"
          @click="changeOrder = !changeOrder"
        >
          {{ $t('BUTTONS.CHANGE_ORDER') }}
        </el-button>
      </div>
    </el-row>

    <el-table
      v-loading="loading || saveLoading"
      class="template-edit__table"
      :data="parameters"
      :row-class-name="tableRowClassName"
      style="width: 100%"
    >
      <el-table-column
        align="center"
        width="50"
      >
        <template slot-scope="scope">
          <i
            v-if="lockedParameters.indexOf(scope.row.parameter.toLowerCase()) !== -1"
            class="material-icons"
          >lock</i>
        </template>
      </el-table-column>
      <el-table-column
        prop="parameter"
        min-width="200"
        :label="$t('TEMPLATES.EDIT.PARAMETER')"
      />
      <el-table-column
        :label="$t('TEMPLATES.EDIT.DISPLAY_NAME')"
        min-width="200"
      >
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.display_name"
            :disabled="lockedParameters.indexOf(scope.row.parameter.toLowerCase()) !== -1"
            placeholder=""
          />
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('TEMPLATES.EDIT.TYPE')"
        width="150"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.type === 1">{{ $t('PARAMETERS.TYPES.INPUT') }}</span>
          <span v-if="scope.row.type === 2">{{ $t('PARAMETERS.TYPES.NUMBER') }}</span>
          <span v-if="scope.row.type === 3">{{ $t('PARAMETERS.TYPES.RADIO') }}</span>
          <span v-if="scope.row.type === 4">{{ $t('PARAMETERS.TYPES.DROPDOWN') }}</span>
          <span v-if="scope.row.type === 5">{{ $t('PARAMETERS.TYPES.MULTI_SELECTION') }}</span>
          <span v-if="scope.row.type === 6">{{ $t('PARAMETERS.TYPES.DATEPICKER') }}</span>
          <span v-if="scope.row.type === 7">{{ $t('PARAMETERS.TYPES.TEXTAREA') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="maxlength"
        :label="$t('TEMPLATES.EDIT.MAX_LENGTH')"
        width="120"
      />
      <el-table-column
        align="center"
        :label="$t('TEMPLATES.EDIT.REQUIRED')"
        width="120"
      >
        <template slot-scope="scope">
          <el-button
            :disabled="lockedParameters.indexOf(scope.row.parameter.toLowerCase()) !== -1"
            type="text"
            @click="toggleRequired(scope.$index, scope.row)"
          >
            <i
              v-if="scope.row.required"
              class="material-icons"
            >done</i>
            <i
              v-if="!scope.row.required"
              class="material-icons danger"
            >close</i>
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('TEMPLATES.EDIT.DISPLAYED')"
        width="120"
      >
        <template slot-scope="scope">
          <el-checkbox
            v-model="scope.row.displayed"
            :disabled="lockedParameters.indexOf(scope.row.parameter.toLowerCase()) !== -1"
          />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('TEMPLATES.EDIT.REF_VALUES')"
        width="200"
      >
        <template slot-scope="scope">
          <div
            v-if="scope.row.type === 5 || scope.row.type === 4"
          >
            <div v-if="scope.row.ref_tablename">
              <el-tag
                type="info"
                size="mini"
              >
                {{ scope.row.ref_tablename }}
              </el-tag>
            </div>
            <el-button
              :disabled="lockedParameters.indexOf(scope.row.parameter.toLowerCase()) !== -1"
              type="text"
              size="small"
              @click="editReferencial(scope.$index, scope.row)"
            >
              {{ $t('BUTTONS.EDIT') }}
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <el-row
      class="template-edit__footer"
      type="flex"
      justify="space-around"
      align="middle"
    >
      <el-col>
        <el-button
          type="primary"
          :loading="saveLoading"
          round
          @click="saveTemplate"
        >
          {{ $t('BUTTONS.SAVE') }}
        </el-button>
        <el-button
          type="info"
          round
          @click="goBackTemplates"
        >
          {{ $t('TEMPLATES.EDIT.BACK') }}
        </el-button>
      </el-col>
    </el-row>

    <EditReferencialModal
      v-if="selectedParam"
      :client-id.sync="clientId"
      :selected-param.sync="selectedParam"
      :new-referencial.sync="newReferencial"
      :show-edit-referencial-modal.sync="showEditReferencialModal"
    />

    <!-- CHANGE ORDER PANEL -->
    <el-drawer
      size="470px"
      class="edit-drawer"
      direction="rtl"
      :visible.sync="changeOrder"
      :destroy-on-close="true"
      :wrapper-closable="false"
    >
      <template slot="title">
        <div class="title">
          {{ $t('TEMPLATES.EDIT.CHANGE_PARAMETERS_ORDER') }}
        </div>
        <div class="edit-drawer__subtitle">
          {{ $t('TEMPLATES.EDIT.CHANGE_PARAMETERS_SUBTITLE') }}
        </div>
      </template>
      <EditParametersOrderPanel
        class="edit-drawer__panel"
        :list="parametersToReorder"
        :visible.sync="changeOrder"
        @update="onOrderChanged"
      />
    </el-drawer>
  </div>
</template>

<script>
import { orderBy } from 'lodash-es'

import Navigation from '@/components/Navigation'
import EditReferencialModal from '@/components/Parameters/EditReferencialModal'
import EditParametersOrderPanel from '@/components/Parameters/EditParametersOrderPanel'

export default {
  name: 'TemplatesPage',
  components: { Navigation, EditReferencialModal, EditParametersOrderPanel },
  data () {
    return {
      clientId: null,
      client: null,
      templateId: '',
      template: null,
      parameters: null,
      saveLoading: false,
      selectedParam: null,
      newReferencial: null,
      updatedParam: null,
      changeOrder: false,
      showEditReferencialModal: false,
      lockedParameters: [
        'id_mailingtelmobile',
        'id_mailingpostal',
        'id_mailingtelmobile',
        'id_mailing',
        'campaign_id',
        'sortiecible',
        'id_typeconsentement',
        'id_mailingpushnotification',
        'ddl_apps_id',
        'refresh_target',
        'source_uid',
        'nomfichier',
        'source_segment',
        'ddl_id_sending_segment',
        'trigger_id'
      ]
    }
  },
  computed: {
    loading () {
      return this.$store.getters['templates/loading']
    },
    parametersToReorder () {
      return this.parameters ? this.parameters.filter(p => this.lockedParameters.indexOf(p.parameter.toLowerCase()) === -1) : []
    }
  },
  watch: {
    'newReferencial': function (saved) {
      if (saved) {
        this.getParameters()
        this.newReferencial = false
        this.$notify({
          title: 'Success',
          message: this.$t('PARAMETERS.REFERENCIAL.SUCCESS_SAVED'),
          type: 'success'
        })
      }
    }
  },
  created () {
    this.clientId = this.$route.params.clientId
    this.templateId = this.$route.params.templateId
    this.getClient()
    this.getTemplate()
  },
  methods: {
    onOrderChanged (unlockedParams) {
      unlockedParams = orderBy(unlockedParams, ['display_order'], ['asc'])
      let lockedParams = this.parameters.filter(p => this.lockedParameters.indexOf(p.parameter.toLowerCase()) !== -1)

      this.parameters = [...lockedParams, ...unlockedParams]

      this.saveTemplate()
      this.changeOrder = false
    },
    goBackTemplates () {
      this.$router.push({ name: 'clients-templates', params: { clientId: this.clientId } })
    },
    tableRowClassName ({ row }) {
      if (this.lockedParameters.indexOf(row.parameter.toLowerCase()) !== -1) {
        return 'locked-row'
      }
      return ''
    },
    editReferencial (index, row) {
      this.selectedParam = row
      this.showEditReferencialModal = true
    },
    toggleRequired (index, row) {
      if (row.required) {
        row.required = false
      } else {
        row.required = true
      }
    },
    getClient () {
      this.$store.dispatch('clients/getItem', this.clientId)
        .then(response => {
          this.client = response
        })
    },
    getTemplate () {
      this.$store.dispatch('templates/getItem', { clientId: this.clientId, templateId: this.templateId })
        .then(response => {
          this.template = response
          this.getParameters()
        })
    },
    getParameters () {
      this.$store.dispatch('templates/getParameters', { clientId: this.clientId, templateId: this.templateId })
        .then(response => {
          // reorder parameters, always display locked params first.
          let locked = response.filter(p => this.lockedParameters.indexOf(p.parameter.toLowerCase()) !== -1)
          locked = orderBy(locked, ['display_order'], ['asc'])

          let unlocked = response.filter(p => this.lockedParameters.indexOf(p.parameter.toLowerCase()) === -1)
          unlocked = orderBy(unlocked, ['display_order'], ['asc'])

          this.parameters = [...locked, ...unlocked]
        })
    },
    saveTemplate () {
      this.saveLoading = true

      // SAVE TEMPLATE NAME
      this.$store.dispatch('templates/updateTemplate', { clientId: this.clientId, templateId: this.templateId, display_name: this.template.display_name, parameters: this.parameters })
        .then(() => {
        })
        .catch(() => {
          this.saveLoading = false
        })

      // SAVE EACH PARAMETERS
      this.$store.dispatch('parameters/updateParameters', { clientId: this.clientId, parameters: this.parameters })
        .then(() => {
          this.saveLoading = false
          this.$notify({
            title: 'Success',
            message: this.$t('TEMPLATES.EDIT.SUCCESS_SAVED'),
            type: 'success'
          })
        })
        .catch(() => {
          this.saveLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";

.template-edit {
  .material-icons {
    &.danger {
      color: $--color-danger;
    }
  }
  &__change-order {
    margin-top: 30px;
    button {
      background:$--color-white !important;
      color: $--color-button-text !important;
      border-color: $--color-button-text !important;
      &:hover {
        border-color: $--color-button-border-hover !important;
      }
    }
  }
  &__name {
    margin: 1rem 0 2rem 0;
    &-label {
      margin-bottom: 15px;
      color: $--color-text-grey
    }
    &-input {
      input {
        font-size: 18px;
        width: 70%;
      }
    }
  }
  &__table {
    color: $--color-text-grey;
    .locked-row {
      background: $--color-table-lockrow;
    }
  }
  .el-table {
    color: $--color-text-grey;
    z-index: 0;
  }
  &__footer {
    margin: 2rem 0;
    text-align: right;
  }
}
.edit-drawer {
  .el-drawer {
    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .title {
        font-size: 1rem;
        color: $--color-title-drawer
      }
    }
    &__close-btn {
      background-color: transparent;
      border: none;
      cursor: pointer;
      position: absolute;
      top: 15px;
      right: 15px
    }
    .el-icon-close {
      font-size: 1.6rem;
      color: $--color-action-close;
      font-weight: 700;
      opacity: .7;
    }
  }
  &__subtitle {
    color: $--color-text-grey;
    margin-top: 20px;
    font-size: 12px;
  }
}
</style>
