import Vue from 'vue'

import Router from 'vue-router'

// container
import AppContainer from '@/pages/AppContainer'
import MainContainer from '@/pages/MainContainer'
import i18n from '@/languages/i18n'

// pages
import ClientsPage from '@/pages/ClientsPage'
import ClientsTemplatesPage from '@/pages/ClientsTemplatesPage'
import ParametersPage from '@/pages/ParametersPage'
import TemplateEditPage from '@/pages/TemplateEditPage'
import ResultsPage from '@/pages/ResultsPage'

// import store from '@/store'

Vue.use(Router)

const guardRoute = function (to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  const store = router.app.$options.store
  let user = store.getters.user
  if (!user) {
    store.dispatch('auth/getProfile').then((res) => {
      if (res.status === 200) {
        user = res.data
        if (user.lang) {
          const language = user.lang || 'en'
          Vue.moment.locale(language)
          i18n.locale = language
        }
      }
      store.dispatch('switch/getClients').finally(() => {
        document.body.setAttribute("loaded", 1)
        next()
      })
    })
  } else {
    next()
  }
}

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/app',
      component: AppContainer,
      children: [
        {
          name: '',
          path: '/app',
          component: MainContainer,
          children: [
            {
              name: 'clients',
              path: '/clients',
              component: ClientsPage,
              meta: { title: 'META_TITLE.CLIENTS_LIST' }
            },
            {
              name: 'clients-templates',
              path: '/clients/:clientId/templates',
              component: ClientsTemplatesPage,
              meta: { title: 'META_TITLE.CLIENTS_TEMPLATES' }
            },
            {
              name: 'template-parameters',
              path: '/clients/:clientId/templates/:templateId/parameters',
              component: ParametersPage,
              props: true,
              meta: { title: 'META_TITLE.TEMPLATE_PARAMETERS' }
            },
            {
              name: 'template-results',
              path: '/clients/:clientId/templates/:templateId/results/:resultId',
              component: ResultsPage,
              props: true,
              meta: { title: 'META_TITLE.RESULTS' }
            },
            {
              name: 'template-edit',
              path: '/clients/:clientId/templates/:templateId/edit',
              component: TemplateEditPage,
              meta: { title: 'META_TITLE.TEMPLATE_EDIT' }
            }
          ],
          beforeEnter: guardRoute
        }
      ]
    },
    // default route when none of the above matches
    {
      path: '*',
      redirect: '/clients'
    }
  ]
})

export default router
