<template>
  <el-card class="card-item__inner">
    <client-logo
      v-if="client.logo_url"
      :src="client.logo_url"
      :title="client.name"
      :height="80"
      :width="150"
    />
    <div class="card-item__title text-center text-semibold">
      {{ client.name }}
    </div>
  </el-card>
</template>

<script>
import ClientLogo from '@/components/Clients/ClientLogo'

export default {
  name: 'ClientsListItem',
  components: { ClientLogo },
  props: {
    client: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";

.card-item__inner {
  background-color: #fff;
  color: #303133;
  padding: 10px;
  height: calc(150px - 2*10px);

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  transition: box-shadow;
  -webkit-transition: box-shadow .3s; /* Safari */

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  }

  .card-item__title {
    width: 100%;
    color: #848484;
    margin-top: 10px;
  }
}

.default-item .card-item__inner {
  background-color: $--color-secondary;

  box-shadow: none;
  transition: background-color;
  -webkit-transition: background-color .3s; /* Safari */

  &:hover {
    background-color: lighten($--color-secondary, 5%)
  }

  .card-item__title {
    color: #fff;
    font-size: 16px;
  }
}
</style>
