<template>
  <div class="page-wrapper templates-page">
    <Navigation
      :router="{ name: 'clients' }"
      :client="client"
      :title="$t('TEMPLATES.TITLE')"
    />

    <div
      v-loading="loading"
      class="templates-list"
    >
      <EmptyList
        v-show="!loading && tableData.length === 0 && !error_client || error_templates"
        :message="$t('TEMPLATES.NO_TEMPLATES')"
      />
      <EmptyList
        v-show="!loading && error_client"
        :message="$t('TEMPLATES.NO_CLIENT')"
      />
      <el-table
        v-if="tableData.length > 0"
        :data="tableData"
        style="width: 100%"
      >
        <el-table-column
          label=""
          align="center"
          width="50"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.channel_id === 1">
              <i class="material-icons search-input__icon">mail_outline</i>
            </span>
            <span v-if="scope.row.channel_id === 2">
              <i class="material-icons search-input__icon">chat</i>
            </span>
            <span v-if="scope.row.channel_id === 3">
              <i class="material-icons search-input__icon">notifications_none</i>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          :label="'Template (' + tableData.length + ')'"
          min-width="200"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.display_name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="SP_Name"
          min-width="300"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('TEMPLATES.ACTIONS')"
          width="200"
        >
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              :content="$t('TEMPLATES.RUN')"
              placement="left"
            >
              <el-button
                v-loading="isLoadingResults(scope.row)"
                size="mini"
                type="primary"
                @click="handleRun(scope.$index, scope.row)"
              >
                <i class="material-icons md-18">play_arrow</i>
              </el-button>
            </el-tooltip>
            <el-button
              size="mini"
              @click="handleEdit(scope.$index, scope.row)"
            >
              <i class="material-icons md-18">edit</i>
            </el-button>
            <el-tooltip
              v-if="scope.row.result_finished_at"
              class="item"
              effect="dark"
              :content="$t('TEMPLATES.LATEST') + ' : ' + $moment(scope.row.result_created_at).format('MMMM Do YYYY, HH:mm:ss')"
              placement="right"
            >
              <el-button
                size="mini"
                @click="handleResult(scope.$index, scope.row)"
              >
                <i class="material-icons md-18">visibility</i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import EmptyList from '@/components/EmptyList'
import Navigation from '@/components/Navigation'

export default {
  name: 'TemplatesPage',
  components: { EmptyList, Navigation },
  data () {
    return {
      clientId: '',
      client: null,
      tableData: [],
      error_client: false,
      error_templates: false
    }
  },
  computed: {
    loading () {
      return this.$store.getters['templates/loading']
    }
  },
  created () {
    this.clientId = this.$route.params.clientId
    this.getClient()
  },
  methods: {
    handleRun (index, row) {
      this.$router.push({ name: 'template-parameters', params: { clientId: this.clientId, templateId: row.id } })
    },
    handleResult (index, row) {
      this.$router.push({ name: 'template-results', params: { clientId: this.clientId, templateId: row.id, resultId: row.result_id } })
    },
    handleEdit (index, row) {
      this.$router.push({ name: 'template-edit', params: { clientId: this.clientId, templateId: row.id } })
    },
    getClient () {
      this.$store.dispatch('clients/getItem', this.clientId)
        .then(response => {
          this.client = response
          this.getTemplates()
        })
        .catch(() => {
          this.error_client = true
        })
    },
    getTemplates () {
      this.$store.dispatch('templates/getItems', { clientId: this.clientId })
        .then(response => {
          this.tableData = response.data
        })
        .catch(() => {
          this.error_templates = true
        })
    },
    isLoadingResults (row) {
      if (row.param_output_target !== '1') {
        return !row.result_finished_at && row.result_created_at
      }
      // if output target is set, should check vip proc status too
      const targetCalculationSuccess = row.result_finished_at && row.result_created_at && !row.result_error_msg
      const vipCalculationLoading = !row.result_vip_finished_at && !row.result_vip_error_msg
      return targetCalculationSuccess && vipCalculationLoading
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";
.templates-page {
  .templates-list {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 1rem;
    min-height: 400px;

    .material-icons {
      &.md-18 {
        font-size: 18px;
      }
    }

    .el-table,
    .el-table tr,
    .el-table th {
      background: none
    }

    .el-table {
      td {
        color: #888;
      }
    }
  }

  &__footer {
    text-align: center;
    padding: 2rem 0;
  }
}
</style>
