<template>
  <div>
    <div class="header">
      <div class="header-bar">
        <router-link
          :to="{ name: 'clients' }"
          class="header-name"
        >
          <Logo />
        </router-link>
        <span class="header-middle" />
        <span class="header-right">
          <!-- SWITCH -->
          <nmp-switch
            :apps="apps"
            :clients="clients"
            :current-switch-client="currentSwitchClient"
            @update:currentSwitchClient="updateSwitchClient"
          />

          <el-dropdown
            v-if="user"
            trigger="hover"
            @command="handleCommand"
          >
            <el-button
              class="header-account header-button el-dropdown-link"
              type="text"
            >
              <img
                v-show="user.avatar_url"
                :src="user.avatar_url"
                class="header-round-img"
                alt="User avatar"
              >
              <img
                v-show="!user.avatar_url"
                v-holder="'holder.js/30x30?text='+getInitials(user.first_name + ' ' + user.last_name)"
                class="header-round-img"
                alt="User avatar"
              >
            </el-button>
            <el-dropdown-menu
              slot="dropdown"
              class="menu-account"
            >
              <div class="menu-account--detail-container">
                <div class="menu-account--avatar-container">
                  <img
                    v-show="user.avatar_url"
                    :src="user.avatar_url"
                    style="border-radius: 50%;"
                    width="48"
                    height="48"
                    alt="User avatar"
                  >
                  <img
                    v-show="!user.avatar_url"
                    v-holder="'holder.js/48x48?text='+getInitials(user.first_name + ' ' + user.last_name)"
                    class="menu-account--avatar"
                    alt="User avatar"
                  >
                </div>
                <div class="mb-1">
                  {{ user.first_name + ' ' + user.last_name }}
                </div>
                <div class="mb-1 menu-account--email">
                  {{ user.email }}
                </div>
              </div>
              <div>
                <el-dropdown-item
                  command="profile"
                >
                  <i class="material-icons">perm_identity</i>
                  {{ $t('BUTTONS.PROFILE') }}
                </el-dropdown-item>
                <el-dropdown-item
                  command="logout"
                >
                  <i class="material-icons">arrow_forward</i>
                  {{ $t('BUTTONS.SIGN_OUT') }}
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getInitials } from '@/filters'
import { NmpSwitch } from '@team-crm-automation/nmp-components-vue2'
import Logo from './Logo'

export default {
  name: 'AppHeader',
  components: { Logo, NmpSwitch },
  data() {
    return {
      currentSwitchClient: undefined
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    },
    clients () { 
      return this.$store.getters['switch/clients'] || []
    },
    apps () {
      return this.$store.getters['switch/apps'] || []
    },
  },
  methods: {
    handleCommand (command) {
      switch (command) {
        case 'logout':
          localStorage.removeItem('user')
          this.$router.push({ name: 'login' })
          this.$store.dispatch('auth/logout')
          break
        case 'profile':
          window.open('/profile')
          break
      }
    },
    getInitials: getInitials,
    async updateSwitchClient (client) {
      await this.$store.dispatch('switch/getApps', client ? client['id'] : null)
      this.currentSwitchClient = client
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";

.header {

  .header-name  {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    margin-right: 32px;
  }
  .el-button.el-button--text {
    padding: 7px;
  }
  .header-account.el-button.el-button--text {
    padding: 3px;

    &.mono-client-mode {
      padding: 4px 10px;
      background-color: lighten($--color-primary, 10%);

      .client-logo {
        height: 30px;
      }
    }
  }
}
.el-dropdown-menu.menu-account {
  padding: 0;
}

.menu-account {
  width: 240px;

  .el-dropdown-menu__item {
    padding-left: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
    border-top: 1px solid #EEF1F6;
  }

  i {
    vertical-align: text-bottom;
    font-size: 18px;
    width: 30px;
    text-align: center;
  }
}

.menu-account--detail-container {
  padding: 20px 10px 20px;
  text-align: center;
  font-size: 14px;
}

.menu-account--avatar-container {
  width: 48px;
  height: 48px;
  margin: 0 auto 10px;
}

.menu-account--avatar {
  width: 48px;
  height: 48px;
  box-shadow: none;
  border-radius: 50%;
}

.menu-account--email {
  color: #aaa;
}
</style>
