<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
}
</script>
<style lang="scss">
@import "src/assets/style/_variables.scss";

</style>
