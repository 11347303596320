<template>
  <div class="">
    <el-dialog
      custom-class="sample-modal"
      :fullscreen="true"
      title="Samples"
      :visible.sync="showSamplesModal"
      width="30%"
      :show-close="false"
      center
    >
      <div class="results-samples">
        <el-row
          type="flex"
          justify="center"
          :gutter="24"
        >
          <el-col
            v-for="header in samplesHeaders"
            :key="header"
            :span="24 / samplesHeaders.length + 5"
          >
            {{ header }}
          </el-col>
        </el-row>
        <el-row
          v-for="item in samples"
          :key="item.id"
          type="flex"
          justify="center"
          :gutter="24"
        >
          <el-col
            v-for="(value, index) in item"
            :key="value + index"
            :span="24 / samplesHeaders.length + 5"
          >
            {{ value }}
          </el-col>
        </el-row>
      </div>

      <div class="results-samples__footer">
        <el-button
          round
          type="primary"
          @click="closeModal"
        >
          {{ $t('RESULTS.CLOSE_BUTTON') }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>

export default {
  name: 'EmptyList',
  components: {},
  props: {
    showSamplesModal: {
      type: Boolean,
      default: false
    },
    samples: {
      type: Array,
      default: () => []
    },
    samplesHeaders: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    closeModal () {
      this.$emit('update:showSamplesModal', false)
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";
.sample-modal {
  .el-dialog__title {
    font-size: 1.3rem;
    color: #00A5B5;
    font-weight: normal;
  }
}
.results-samples {
  overflow: auto;
  .el-row {
    width: 180%;
    border-bottom: 1px solid #eee;
    &:first-child {
      background: #eee;
      text-align: center;
      font-weight: 600;
      font-size: 12px;
    }
    .el-col {
      color: #888;
      padding: 10px 20px;
      border-right: 1px solid #eee;
      width: 150px;
      word-break: break-all;
    }
  }

  &__footer {
    text-align: center;
    margin-top: 20px;
    position: fixed;
    top: 0;
    right: 30px;
  }
}

</style>
