<template>
  <div class="page-wrapper clients-page">
    <div class="top-nav">
      <h1 class="top-nav__title text-center">
        <span>{{ $t('CLIENTS.TITLE') }}</span>
      </h1>
      <span class="logoBlue" />
    </div>
    <SearchBar
      :placeholder="$t('CLIENTS.SEARCH_LABEL')"
      :callback="getClients"
    />

    <ClientsList
      v-if="total > 0"
      v-loading="isLoadingClients"
      :items="clients"
    />

    <EmptyList
      v-show="total === 0 && !isLoadingClients"
      :message="$t('CLIENTS.NO_CLIENTS')"
    />

    <div
      v-show="total > 0"
      class="clients-page__pagination"
    >
      <i18n
        class="clients-page__total"
        path="CLIENTS.CLIENTS_PAGINATION"
        tag="label"
        for="tos"
      >
        <span>{{ clients.length }}</span>
        <span>{{ total }}</span>
      </i18n>
      <div
        v-show="clients.length < total"
        class="clients-page__load"
      >
        <el-button
          type="primary"
          size="small"
          :loading="isLoadingClients"
          round
          @click="loadNextClients()"
        >
          {{ $t("CLIENTS.BUTTON_MORE") }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClientsList from '@/components/Clients/ClientsList'
import SearchBar from '@/components/Clients/SearchBar'
import EmptyList from '@/components/EmptyList'

export default {
  name: 'ClientsPage',
  components: { ClientsList, SearchBar, EmptyList },
  data () {
    return {
      clients: [],
      page: 1,
      total: 0,
      query: null,
      isLoadingClients: false
    }
  },
  computed: {},
  created () {
    // retrieve available clients
    this.getClients()
  },
  methods: {
    getClients (query, page) {
      this.isLoadingClients = true

      // If new query search, init page to 1
      if (this.query !== query) {
        this.page = 1
      }

      this.query = query

      let params = {
        l: 9,
        p: this.page,
        ob: ['name']
      }

      if (this.query) {
        params.s = this.query
      }

      this.$store.dispatch('clients/getItems', params).then(response => {
        if (page) {
          for (var i = 0; i < response.data.clients.length; i++) {
            this.clients.push(response.data.clients[i])
          }
          this.total = response.data.total
        } else {
          this.clients = response.data.clients
          this.total = response.data.total
        }
        this.isLoadingClients = false
      })
    },
    loadNextClients () {
      this.page += 1
      this.getClients(this.query, this.page)
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";
.logoBlue {
  background-image: url("../assets/img/logo-blue.svg");
  width: 160px;
  height: 40px;
  display: block;
  background-repeat: no-repeat;
}

.clients-page {
  &__pagination {
    padding: 2rem 0 4rem 0;
    text-align: center;
  }

  &__total {
    font-style: italic;
  }

  &__load {
    margin-top: 20px;
  }

  .top-nav {
    margin-bottom: 2rem;
    &__title {
      font-size: 14px;
      span {
        color: #888;
      }
    }
  }
}
</style>
