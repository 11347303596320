import Vue from 'vue'
import config from '@/config'

const SET_ITEMS = 'SET_ITEMS'
const SET_LOADING = 'SET_LOADING'

const moduleParameters = {
  namespaced: true,
  state: {
    item: null,
    items: [],
    loading: false
  },
  mutations: {
    [SET_ITEMS] (state, items) {
      Vue.set(state, 'items', items)
    },
    [SET_LOADING] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getParameterValues ({ commit }, { data, clientId, parameterId }) {
      const url = `${config.API_URL}/clients/${clientId}/parameters/${parameterId}/values`
      commit(SET_LOADING, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, { params: data })
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    getOptinTypes ({ commit }, { clientId, channelId }) {
      const url = `${config.API_URL}/clients/${clientId}/channel/${channelId}/optins`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response.data.objects)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    getPushApplications ({ commit }, { clientId }) {
      const url = `${config.API_URL}/clients/${clientId}/applications`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {})
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response.data.objects)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    updateParameters ({ commit }, { clientId, parameters }) {
      const url = `${config.API_URL}/clients/${clientId}/parameters/update`
      return new Promise((resolve, reject) => {
        Vue.axios.patch(url, {
          parameters: parameters
        })
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    },
    getTableColumns ({ commit }, { clientId, tableName }) {
      const url = `${config.API_URL}/clients/${clientId}/columns`
      return new Promise((resolve, reject) => {
        Vue.axios.get(url, {
          params: {
            'table_name': tableName,
            'deduct_id_value': 1
          }
        })
          .then(response => {
            commit(SET_LOADING, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response)
          })
          .catch(error => {
            commit(SET_LOADING, false)
            reject(error)
          })
      })
    }
  },
  getters: {
    item: state => state.item,
    items: state => state.items,
    loading: state => state.loading
  }
}

export default moduleParameters
