<template>
  <div class="page-wrapper results-page">
    <Navigation
      :router="{name: 'clients-templates', params: {clientId: clientId}}"
      :client="client"
      :title="$t('RESULTS.TITLE')"
    />
    <Processing
      v-if="loading"
      :message="$t('LOADING')"
    />

    <ProcResults
      v-if="results"
      :client-id="clientId"
      :template="template"
      :sortie-cible.sync="sortieCible"
      :results="results"
      :user="user"
      :used-parameters="parametersByName"
    />

    <el-row
      type="flex"
      align="middle"
      justify="center"
    >
      <el-col
        v-if="!errorDetails && !loading"
        :span="6"
      >
        <el-badge
          :value="samples.length"
          class="item"
          type="warning"
        >
          <el-button
            round
            type="primary"
            :disabled="samples.length === 0"
            @click="checkSamples"
          >
            {{ $t('RESULTS.SAMPLES_BUTTON') }}
          </el-button>
        </el-badge>
      </el-col>
      <el-col
        v-if="!loading"
        :span="6"
      >
        <el-button
          round
          type="primary"
          :disabled="disableChange"
          @click="changeParams"
        >
          {{ $t('RESULTS.CHANGE_BUTTON') }}
        </el-button>
      </el-col>
    </el-row>

    <SamplesModal
      v-if="showSamplesModal"
      :show-samples-modal.sync="showSamplesModal"
      :samples="samples"
      :samples-headers="samplesHeaders"
    />
  </div>
</template>
<script>
import Navigation from '@/components/Navigation'
import ProcResults from '@/components/Templates/ProcResults'
import SamplesModal from '@/components/Templates/SamplesModal'
import Processing from '@/components/Templates/Processing'

export default {
  name: 'ParametersPage',
  components: { Navigation, ProcResults, SamplesModal, Processing },
  data () {
    return {
      showSamplesModal: false,
      loading: true,

      clientId: '',
      client: null,
      templateId: '',
      creationDate: null,
      sortieCible: '0',

      resultId: '',
      results: null,

      samplesHeaders: [],
      samples: [],

      user: null,
      template: null,
      usedParameters: null,
      errorDetails: null,

      parametersByName: []
    }
  },
  computed: {
    disableChange () {
      if (this.sortieCible !== '1') {
        return !this.results.finished_at
      }
      // If output target = '1', should wait for vip proc to finish or an error to happen
      return !this.results.vip_finished_at && !this.results.error_msg
    }
  },
  beforeMount () {
    this.clientId = this.$route.params.clientId
    this.templateId = this.$route.params.templateId
    this.resultId = this.$route.params.resultId
    this.sortieCible = this.$route.params.sortieCible
    this.getClient()
    this.getResults()
    this.getSamples()
  },
  created () {
  },
  methods: {
    changeParams () {
      this.$router.push({
        name: 'template-parameters',
        params: {
          clientId: this.clientId,
          templateId: this.templateId,
          // usedParameters: this.usedParameters,
          usedSortieCible: this.sortieCible
        }
      })
    },
    checkSamples () {
      this.showSamplesModal = true
    },
    getClient () {
      this.$store.dispatch('clients/getItem', this.clientId)
        .then(response => {
          this.client = response
        })
        .catch(() => {
          this.error_client = true
        })
    },
    getSamples () {
      this.$store.dispatch('results/getSamples', { clientId: this.clientId, templateId: this.templateId })
        .then(response => {
          this.samplesHeaders = response.data.meta.headers
          let objects = response.data.objects
          this.samples = []
          for (let i = 0; i < objects.length; i++) {
            let sample = objects[i]
            this.samples.push(Object.assign({}, sample))
          }
        })
    },
    getResults () {
      this.$store.dispatch('results/getResults', { clientId: this.clientId, resultId: this.resultId })
        .then(response => {
          if (response.data) {
            this.template = response.data.template
            this.user = response.data.user
            this.usedParameters = response.data.parameters

            // Format the parameters, organize by parameter name
            let parametersNameArray = []
            for (let i = 0; i < this.usedParameters.length; i++) {
              let param = this.usedParameters[i]
              if (parametersNameArray.indexOf(param.parameter) === -1) {
                parametersNameArray.push(param.parameter)
                this.parametersByName.push({
                  'name': param.parameter,
                  'params': []
                })
              }
              for (let j = 0; j < this.parametersByName.length; j++) {
                if (this.parametersByName[j].name === param.parameter) {
                  this.parametersByName[j].params.push(param)
                }
              }
            }
            this.results = response.data
          }
          this.loading = false

          if (this.sortieCible === '1' && this.results.error_msg) {
            // Call the status API to test if VIP proc is finished
            const callbackFn = (response) => {
              this.processResponse(response, this.results.token_id, callbackFn)
            }
            this.checkStatus(this.results.token_id, callbackFn)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    checkStatus (token, callback) {
      this.$store.dispatch('results/checkStatus', { clientId: this.clientId, tokenId: token })
        .then(response => {
          callback(response)
        })
    },
    processResponse (response, token, callbackFn) {
      if (this.stopCheckStatus) {
        return false
      }
      if (!response || !response.vip_finished_at) {
        window.setTimeout(() => {
          this.checkStatus(token, callbackFn)
        }, 3000)
      } else {
        this.results.vip_error_msg = response.vip_error_msg
        this.results.vip_finished_at = response.vip_finished_at
      }
    }
  }
}
</script>
<style lang="scss">
@import "src/assets/style/_variables.scss";
</style>
