<template>
  <div class="top-nav">
    <h1 class="text-center">
      {{ title }}
    </h1>
    <h2
      v-if="subtitle"
      class="text-center"
    >
      {{ subtitle }}
    </h2>

    <div v-if="client">
      <el-button
        type="text"
        @click="goBack"
      >
        <div class="top-nav__client">
          <i class="el-icon-arrow-left el-icon-left" />
          <div
            v-show="client.logo_url"
            class="top-nav__client-thumbnail"
          >
            <img
              class="image"
              :src="client.logo_url"
              :alt="client.name"
              :title="client.name"
            >
          </div>
          <div
            class="top-nav__client-name"
          >
            {{ client.name }}
          </div>
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Navigation',
  props: {
    router: {
      type: Object,
      default: () => {}
    },
    client: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: [String, null],
      default: null
    }
  },
  methods: {
    goBack () {
      this.$router.push(this.router)
    }
  }
}
</script>

<style lang="scss">
@import "src/assets/style/_variables.scss";

.top-nav {
  padding: 2em 0;

  .el-button--text {
    color: #3D3E3F;
  }

  &__client {
    display: inline-flex;
    align-items: center;
  }

  &__client-name {
    margin-left: 10px;
  }

  &__client-thumbnail {
    margin: 0 20px;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
    display: inline-block;
    border: 1px solid #ddd;
    background: #ffffff;
    position: relative;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}
</style>
