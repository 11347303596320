import Vue from 'vue'
import config from '@/config'

const SET_LOADING_USERS = 'SET_LOADING_USERS'

const moduleUsers = {
  namespaced: true,
  state: {
    loading: false
  },
  mutations: {
    [SET_LOADING_USERS] (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    getItems ({ commit }, searchQuery) {
      let url = `${config.API_URL}/users?sf=first_name,last_name`
      if (searchQuery) {
        url += '&s=' + searchQuery
      }
      commit(SET_LOADING_USERS, true)
      return new Promise((resolve, reject) => {
        Vue.axios.get(url)
          .then(response => {
            commit(SET_LOADING_USERS, false)
            if (response.status !== 200) {
              reject(new Error('Incorrect status'))
            }
            resolve(response.data.objects)
          })
          .catch(error => {
            commit(SET_LOADING_USERS, false)
            reject(error)
          })
      })
    }
  },
  getters: {
    loading: state => state.loading
  }
}

export default moduleUsers
